header[data-v-c917edcd] {
  display: flex;
  height: 3em;
  min-width: 100vw;
  background-color: rgb(85, 255, 241);
  color: white;
  align-items: center;
  justify-content: center;
}
h1[data-v-c917edcd] {
  font-weight: bold;
}
